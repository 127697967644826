.edit-button .ant-btn-primary {
  background: #2196f3;
  border-color: #2196f3;
}

.duplicate-button .ant-btn-primary {
  background: orange;
  border-color: orange;
}

.edit-button .ant-btn-primary:hover {
  background: #1769aa;
  border-color: #1769aa;
}

.duplicate-button .ant-btn-primary:hover {
  background: darkorange;
  border-color: darkorange;
}

table {
  border-collapse: collapse;
  border: 1px solid #333;
}

th {
  background-color: lightblue;
  border: 1px solid #333;
}

td {
  border: 1px solid #333;
}

.center {
  text-align: center;
}

.html-table table {
  width: 100%;
}

.html-table th {
  padding: 5px 20px;
}

.html-table td {
  padding: 5px 15px;
}

.status-table table {
  width: 100%;
}

.status-table th {
  padding: 5px 8px;
}

.status-table td {
  padding: 5px 8px;
  text-align: center;
}

.input-table table {
  width: 100%;
}

.input-table th {
  padding: 5px 20px;
}

.input-table td {
  padding: 1px 1px;
}

.destination-input th {
  padding: 1px 10px;
}

.destination-input td {
  padding: 1px 1px;
}

.business-content th {
  padding: 5px 50px;
}

.business-content td {
  padding: 1px 1px;
}

.staff-select .ant-table-thead > tr > th {
  padding: 1px 1px;
  font-weight: bold;
}

.staff-select .ant-table-tbody > tr > td {
  padding: 1px 1px;
}

.table-size .ant-table-thead > tr > th {
  padding: 5px 10px;
  font-size: 18px;
  font-weight: bold;
}

.table-size .ant-table-tbody > tr > td {
  padding: 5px 10px;
  font-size: 18px;
}
