.ant-btn-primary {
  background: green;
  border-color: green;
}

.ant-btn-primary:hover {
  background: lightgreen;
  border-color: lightgreen;
}

.ant-table-thead > tr > th {
  background-color: lightblue;
}

.clientSearch-button .ant-btn-primary {
  background: orange;
  border-color: orange;
}

.invoiceUpload-button .ant-btn-primary {
  background: #2196f3;
  border-color: #2196f3;
}

.invoiceUpload-del .ant-btn-primary {
  background: #ff4d4f;
  border-color: #ff4d4f;
}

.clientSearch-button .ant-btn-primary:hover {
  background: lightsalmon;
  border-color: lightsalmon;
}

.invoiceUpload-button .ant-btn-primary:hover {
  background: #1769aa;
  border-color: #1769aa;
}

.invoiceUpload-del .ant-btn-primary:hover {
  background: #ff7875;
  border-color: #ff7875;
}

.MuiCardContent-root > h2 {
  color: white;
}
