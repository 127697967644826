.ant-table-row .draggable {
  font-size: 1.2rem;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  transform: rotate(90deg);
}

.gu-mirror {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  background-color: rgba(16, 142, 233, 0.15);
}
